<template>
  <div class="main-content">
    <PromoMobile>
      <template v-if="isABTestEnabled == 0" #controls>
        <VButtonLinkMTS
          :href="btnPromoTry.href"
          class="--xl --violet-gradient promo__controls_btn"
        >
          Войти или зарегистрироваться
        </VButtonLinkMTS>
      </template>
      <template v-else #controls>
        <VButtonLinkMTS
          href="/user-registration"
          class="--xl --violet-gradient promo__controls_btn"
        >
          Войти или зарегистрироваться
        </VButtonLinkMTS>
      </template>
    </PromoMobile>
    <div class="container">
      <ProductsMobile />

      <LazyClientsSliderMobile :substrate-color="'--grey'" class="clients" />

      <LazyHydrate when-visible>
        <LazyServicesMobile />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <LazyPremiseMobile />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <LazyBlockWithNumbersMobile> </LazyBlockWithNumbersMobile>
      </LazyHydrate>
    </div>
    <div class="substrate --white-bg">
      <div class="container">
        <LazyHydrate when-visible>
          <LazyExperienceMobile />
        </LazyHydrate>

        <LazyHydrate when-visible>
          <LazyTaskMobile />
        </LazyHydrate>

        <LazyHydrate when-visible>
          <LazyReviewsMobile
            :title="'Что о\u00A0нас говорят клиенты'"
            :color-card="'--grey'"
            :reviews-list="ReviewsListMobile"
          />
        </LazyHydrate>
      </div>
    </div>

    <div class="container">
      <LazyHydrate when-visible>
        <LazyAdvantagesMobile />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <LazyFaqMobile :title="true" />
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import MainMixin from '~/components/Page/Main/MainMixin'
import ReviewsMixin from '~/components/Desktop/Main/mixins/ReviewsMixin'
import EventBus from '@/mixins/EventBus'
import PromoMobile from '~/components/Mobile/Main/PromoMobile.vue'
import VButtonLinkMTS from '~/components/Common/MTS/VButtonLinkMTS.vue'
import LazyServicesMobile from '~/components/Mobile/Main/Lazy/LazyServicesMobile.vue'
import LazyPremiseMobile from '~/components/Mobile/Main/Lazy/LazyPremiseMobile.vue'
import LazyBlockWithNumbersMobile from '~/components/Mobile/Main/Lazy/LazyBlockWithNumbersMobile.vue'
import LazyExperienceMobile from '~/components/Mobile/Main/Lazy/LazyExperienceMobile.vue'
import LazyTaskMobile from '~/components/Mobile/Main/Lazy/LazyTaskMobile.vue'
import LazyReviewsMobile from '~/components/Mobile/Common/Lazy/LazyReviewsMobile.vue'
import LazyAdvantagesMobile from '~/components/Mobile/Main/Lazy/LazyAdvantagesMobile.vue'
import LazyFaqMobile from '~/components/Mobile/Common/Lazy/LazyFaqMobile.vue'
import LazyClientsSliderMobile from '~/components/Mobile/Main/Lazy/LazyClientsSliderMobile.vue'
import ProductsMobile from '~/components/Mobile/Main/ProductsMobile.vue'

export default {
  name: 'MainPageMobile',
  components: {
    LazyClientsSliderMobile,
    LazyFaqMobile,
    LazyAdvantagesMobile,
    LazyReviewsMobile,
    LazyTaskMobile,
    LazyExperienceMobile,
    LazyBlockWithNumbersMobile,
    LazyPremiseMobile,
    LazyServicesMobile,
    VButtonLinkMTS,
    PromoMobile,
    ProductsMobile,
    LazyHydrate,
  },
  mixins: [EventBus, MainMixin, ReviewsMixin],
}
</script>

<style lang="scss" scoped>
.main-content {
  font-family: 'MTS Compact', 'Arial', sans-serif;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  line-height: 100%;

  .container {
    padding: 0 20px;
  }

  .substrate {
    &.--white-bg {
      border-radius: 24px;
      background: $color-white;
    }
  }

  ::v-deep {
    .page-section__title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      margin-bottom: 16px;
    }

    .clients {
      padding: 32px 0;
    }

    .reviews {
      &__card {
        min-height: 507px;
        max-height: 507px;
        color: $color-dark-2;
      }
    }

    .faq {
      padding-top: 32px;
      padding-bottom: 64px;
    }
  }
}
</style>
